import { ColorToken } from '@robot/ui-theming';
import React from 'react';
import styled, { css } from 'styled-components';
import { designColor } from '../common';
import {
  getColor,
  getSizeProps,
  TxtProps as TxtPropsInner,
} from './utils';

export type TxtProps = TxtPropsInner &
  TxtBaseProps &
  Partial<React.HTMLAttributes<HTMLSpanElement>>;

// expanded span element
export const Txt: React.FC<TxtProps> = ({
  style,
  children,
  className,
  ...rest
}) => {
  const { lineHeight, fontSize } = getSizeProps(rest);
  return (
    <TxtBase
      className={className}
      lineHeight={lineHeight}
      fontSize={fontSize}
      {...rest}
      style={{
        ...style,
      }}
    >
      {children}
    </TxtBase>
  );
};

type TxtBaseProps = {
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;
  mx?: number;
  my?: number;
  pd?: number;
  pt?: number;
  pr?: number;
  pl?: number;
  pb?: number;
  py?: number;
  px?: number;
  flex?: number | boolean;
  clickable?: boolean;
  ellipsis?: boolean;
  block?: boolean;
  bold?: boolean;
  bolder?: boolean;
  color?: keyof ColorToken;
  fontSize?: string;
  lineHeight?: string;
  center?: boolean;
  allCaps?: boolean;
  radius?: number;
};

const TxtBase = styled.span<TxtBaseProps & TxtPropsInner>`
  color: ${p => designColor(p.color) ?? getColor(p) ?? undefined};
  border-radius: ${p => (p.radius ? `${p.radius}px` : undefined)};
  font-size: ${p => p.fontSize ?? undefined};
  line-height: ${p => p.lineHeight ?? undefined};
  font-weight: ${p => (p.bolder ? 'bolder' : p.bold ? 500 : undefined)};
  display: ${p => (p.block ? 'block' : undefined)};
  margin: ${p => (p.mg ? `${p.mg}px` : undefined)};
  margin-left: ${p =>
    p.ml ? `${p.ml}px` : p.mx ? `${p.mx}px` : undefined};
  margin-right: ${p =>
    p.mr ? `${p.mr}px` : p.mx ? `${p.mx}px` : undefined};
  margin-top: ${p =>
    p.mt ? `${p.mt}px` : p.my ? `${p.my}px` : undefined};
  margin-bottom: ${p =>
    p.mb ? `${p.mb}px` : p.my ? `${p.my}px` : undefined};

  padding: ${p => (p.pd ? `${p.pd}px` : undefined)};
  padding-left: ${p =>
    p.pl ? `${p.pl}px` : p.px ? `${p.px}px` : undefined};
  padding-right: ${p =>
    p.pr ? `${p.pr}px` : p.px ? `${p.px}px` : undefined};
  padding-top: ${p =>
    p.pt ? `${p.pt}px` : p.py ? `${p.py}px` : undefined};
  padding-bottom: ${p =>
    p.pb ? `${p.pb}px` : p.py ? `${p.py}px` : undefined};

  overflow: ${p => (p.ellipsis ? 'hidden' : undefined)};
  white-space: ${p => (p.ellipsis ? 'nowrap' : undefined)};
  text-overflow: ${p => (p.ellipsis ? 'ellipsis' : undefined)};

  flex: ${props => (props.flex ? Number(props.flex) : 'none')};
  text-transform: ${props => (props.allCaps ? 'uppercase' : '')};
  cursor: ${props => (props.clickable ? 'pointer' : undefined)};
  ${p =>
    p.center
      ? [
          css`
            display: flex;
            align-items: center;
          `,
        ]
      : undefined}
`;
