import React, { useEffect } from 'react';

export const RegisterBlocker: React.FC = ({ children }) => {
  useEffect(() => {
    const el = document.getElementById('loading');
    setTimeout(() => {
      el && document.body.removeChild(el);
    }, 0);
  }, []);

  // useEffect(() => {
  // if (!isDev()) {
  // // @ts-ignore
  // const { gtag } = window;
  // gtag('config', 'G-GXE9H0SZNE', {
  //   custom_map: { dimension1: 'guest_name' },
  // });
  // }
  // }, []);

  return children as any;
};
