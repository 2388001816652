import { colorConfig, globalToken, useThemeMode } from '@robot/ui-theming';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { isNil, noop } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { View } from '../layout';
import { Txt } from '../txt';

const { colorInteractive } = colorConfig;
const {
  margin,
  colorDivider,
  paddingContentVerticalLG,
  paddingContentHorizontal,
  paddingContentVertical,
} = globalToken;
export type TabViewProps = {
  pages: {
    view: React.ReactNode;
    title: React.ReactNode;
    color?: string;
    onClick?: () => void;
  }[];
  activeIndex?: number;
  onChange?: (index: number) => void;
  tabStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  tabContainerStyle?: React.CSSProperties;
  tabsRootStyle?: React.CSSProperties;
  indicatorStyle?: React.CSSProperties;
  isFull?: boolean;
  tabHeight?: number;
  initPage?: string;
};

export const TabView: React.FC<TabViewProps> = ({
  pages,
  activeIndex,
  onChange,
  tabStyle,
  tabContainerStyle,
  containerStyle = { marginTop: margin },
  indicatorStyle,
  tabsRootStyle,
  isFull,
  tabHeight,
  initPage,
}) => {
  const { mode } = useThemeMode();
  const [value, changeValue] = useState(() => {
    return initPage ? pages.findIndex(p => p.title === initPage) : 0;
  });
  const v = activeIndex ?? value;
  const onChangeIndex = (index: number) => {
    if (pages[index].onClick) {
      pages[index].onClick?.();
      return;
    }

    if (!isNil(activeIndex)) {
      onChange?.(index);
    } else {
      changeValue(index);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo(() => ({ changeTab: onChangeIndex }), []);

  return (
    <TabContext.Provider value={ctx}>
      <StyledTab
        isFull={isFull}
        tabNum={pages.length}
        tabHeight={tabHeight}
      >
        <Tabs
          style={tabsRootStyle}
          value={v}
          onChange={(_, val) => {
            onChangeIndex(val);
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor:
                pages.find((p, i) => i === v)?.color ??
                colorInteractive[mode],
              ...indicatorStyle,
            },
          }}
        >
          {pages.map(({ title }, index) => (
            <Tab
              key={index.toString()}
              style={tabContainerStyle}
              label={
                <Txt h4 style={{ ...tabStyle }} c2={index !== v}>
                  {title}
                </Txt>
              }
            />
          ))}
        </Tabs>
        <View style={containerStyle}>
          {pages.find((p, i) => i === v)?.view}
        </View>
      </StyledTab>
    </TabContext.Provider>
  );
};

const TabContext = React.createContext({
  changeTab: noop as (index: number) => void,
});

export const useTabViewCtx = () => useContext(TabContext);

const { colorTabIndicator, gapTabsContainer } = globalToken.TabView;
const StyledTab = styled.div<{
  tabNum: number;
  isFull?: boolean;
  tabHeight?: number;
}>`
  position: relative;
  width: 100%;
  .MuiTabs-root {
    padding: ${p =>
      p.isFull
        ? '0'
        : `${paddingContentHorizontal}px ${paddingContentVertical}px`};
    margin-right: ${p => (p.isFull ? '0' : paddingContentVerticalLG)}px;
    text-transform: none;
  }
  .MuiTabs-fixed {
    height: ${p => `${p.tabHeight ?? 52}px`};
    border-bottom: ${p =>
      p.isFull ? `1px solid ${colorDivider}` : undefined};
  }
  .MuiTabs-scroller {
    height: 52px;
  }
  .MuiTabs-flexContainer {
    width: 100%;
    gap: ${p => (p.isFull ? '0px' : `${gapTabsContainer}px`)};
  }
  .MuiButtonBase-root {
    flex: ${p => (p.isFull ? '1' : 'none')};
    text-transform: none !important;
    padding: ${p => (!p.isFull ? '12px 0' : '12px 16px')};
    min-width: 0;
    max-width: none !important;
  }
  .MuiTabs-indicator {
    background-color: ${colorTabIndicator} !important;
  }
`;
