import { globalToken } from '@robot/ui-theming';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroupInner from '@mui/material/ToggleButtonGroup';
import { noop } from 'lodash';
import styled from 'styled-components';
import { getDefaultDataKey, SelectableProps } from '../common';
import { useSelectableKey } from '../common/hooks';

export type SegmentControlProps<T extends any[]> = SelectableProps<T>;

export function SegmentControl<T extends any[]>(
  props: SegmentControlProps<T>,
) {
  const {
    options,
    renderOption,
    keyExtractor = getDefaultDataKey,
  } = props;
  const [selectedKey, updateKey] = useSelectableKey(props);

  return (
    <ToggleButtonGroup value={selectedKey} exclusive>
      {options.map(option => {
        const key = keyExtractor(option);
        return (
          <ToggleButton
            key={key}
            value={key}
            onClick={() => {
              updateKey(option, key);
            }}
          >
            {renderOption(option, {
              isOptionInList: true,
              closeSelect: noop,
            })}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

const {
  borderRadius,
  paddingXS,
  colorSurfaceSubdued,
  colorSurfaceSelected,
  colorInteractive,
  colorIconHighlight,
} = globalToken;

const ToggleButtonGroup = styled(ToggleButtonGroupInner)`
  background-color: ${colorSurfaceSubdued};
  padding: 2px;
  border-radius: ${borderRadius}px !important;
  width: 100%;

  .MuiToggleButton-root {
    flex: 1 1 0;
    border: 1px solid;
    padding: ${paddingXS}px;
    text-align: center;
    font-weight: 500;

    &.Mui-selected {
      background-color: ${colorSurfaceSelected};
      border-color: ${colorInteractive} !important;
      border-radius: ${borderRadius}px !important;
    }

    &:not(.Mui-selected) {
      border-color: transparent;
    }

    .MuiTouchRipple-child {
      background-color: ${colorIconHighlight};
    }
  }
`;
