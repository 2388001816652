import {
  BrandColorConfig,
  BrandColorToken,
  setTheme,
} from '@robot/ui-theming';

const colorPrimary = {
  light: '#147DFB',
  dark: '#147DFB',
};
const colorSuccess = {
  dark: '#15803d',
  light: 'rgba(27, 188, 34, 1)',
};
const colorWarning = {
  light: 'rgba(244, 147, 0, 1)',
  dark: '#92400e',
};
const colorError = {
  dark: '#b91c1c',
  light: 'rgba(244, 78, 78, 1)',
};
const colorInfo = {
  light: '#147DFB',
  dark: '#147DFB',
};

export const brandColorConfig: BrandColorConfig = {
  colorPrimary,
  colorSuccess,
  colorWarning,
  colorError,
  colorInfo,
};

export const brandColorTokens: BrandColorToken = {
  colorPrimary: setTheme(colorPrimary),
  colorSuccess: setTheme(colorSuccess),
  colorWarning: setTheme(colorWarning),
  colorError: setTheme(colorError),
  colorInfo: setTheme(colorInfo),
};
