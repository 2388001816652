import { colorConfig, ColorToken, useThemeMode } from '@robot/ui-theming';
import CircularProgressImpl, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { View } from '../layout';

export const CircularProgress: React.FC<
  Omit<CircularProgressProps, 'color' | 'value'> & {
    colors?: (keyof ColorToken)[];
    values?: number[];
    containerStyle?: CSSProperties;
    label?: ReactNode;
    labelStyle?: CSSProperties;
  }
> = ({
  size,
  style,
  colors,
  values = [100],
  containerStyle,
  label,
  labelStyle,
  ...rest
}) => {
  const { mode } = useThemeMode();

  const getRotateDeg = (index: number) => {
    let deg = 180;
    for (let i = 0; i < index; i++) {
      deg += ((values[i] ?? 0) * 360) / 100;
    }
    return deg;
  };

  return (
    <View
      relative
      style={{
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        ...containerStyle,
      }}
    >
      {values.map((value, index) => (
        <CircularProgressInner
          value={value}
          size={size}
          key={index}
          variant="determinate"
          style={{
            position: 'absolute',
            color:
              colorConfig[colors?.[index] ?? 'colorTextDecorative']?.[
                mode
              ],
            transform: `rotate(${getRotateDeg(index)}deg)`,
            zIndex: values.length - index,
          }}
          {...rest}
        />
      ))}
      <View style={labelStyle}>{label}</View>
    </View>
  );
};

const CircularProgressInner = styled(CircularProgressImpl)`
  svg circle {
    stroke-linecap: round;
  }
`;
