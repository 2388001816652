import { colorConfig, useThemeMode } from '@robot/ui-theming';
import { ReactComponent as AngleDown } from '../../assets/icons/angle-down.svg';
import { standardizeSvg } from '../wrappers';

const { colorIcon } = colorConfig;
export default function Angle(props: {
  direction: 'up' | 'down';
  style?: React.CSSProperties;
  className?: string;
}) {
  const { mode } = useThemeMode();
  const { direction, style = {}, ...rest } = props;
  let transform: string = 'scaleY(1)';
  if (direction === 'up') {
    transform = 'scaleY(-1)';
  }

  const Icon = standardizeSvg(AngleDown);
  return (
    <Icon
      style={{
        transform,
        color: colorIcon[mode],
        transition: 'transform 2s',
        pointerEvents: 'none',
        ...style,
      }}
      {...rest}
    />
  );
}
