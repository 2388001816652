// eslint-disable-next-line prettier/prettier
import './styles';

document
  .getElementsByTagName('html')[0]
  .setAttribute('data-theme', 'dark');

const resizeLisetner = () => {
  const html = document.documentElement;
  const cliWidth = html.clientWidth;
  html.style.fontSize = `${Math.min(
    24,
    Math.max(12, 13 * (cliWidth / 1180)),
  )}px`;
};

// Resize text when window size changed
window.onresize = resizeLisetner;

resizeLisetner();
