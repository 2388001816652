/* eslint-disable import/no-mutable-exports */
import { useTheme } from 'styled-components';
import { ColorConfig, GlobalToken } from './interface';
import { ModeProps } from './utils';

export type { ThemeSet } from 'styled-theming';
export * from './interface';
export * from './utils';

/** global ThemeSet tokens */
export let globalToken: GlobalToken;
/** All the Color props that can be access with mode */
export let colorConfig: ColorConfig;

export const setGlobalTheme = (
  token: GlobalToken,
  config: ColorConfig,
) => {
  globalToken = token;
  colorConfig = config;
};

export const useThemeMode = () =>
  useTheme() as { mode: ModeProps; changeTheme: (v: ModeProps) => void };
