import { colorConfig, globalToken, useThemeMode } from '@robot/ui-theming';
import { Modal as ModalImpl } from '@mantine/core';
import {
  closeModal,
  ModalsProvider as ModalsProviderImpl,
  ModalsProviderProps,
  openModal as openModalImpl,
} from '@mantine/modals';
import { isNumber, noop } from 'lodash';
import { createGlobalStyle } from 'styled-components';
import { STYLE_PREFIX } from '../common';
import { PopoverStyle } from '../popover';

export { openConfirmModal } from '@mantine/modals';

let modalId = 0;
export const openModal = (
  options: Parameters<typeof openModalImpl>[0],
) => {
  const id = (++modalId).toString();
  openModalImpl({ ...options, modalId: id.toString() });

  return {
    close: () => closeModal(id),
  };
};

export type ModalProps = {
  title: string | React.ReactNode;
  onClose?: () => void;
  height?: number;
  width?: number;
  visible: boolean;
  footer?: React.ReactNode;
  style?: React.CSSProperties;
  closeOnBackdropClicked?: boolean;
};

const {
  colorText,
  borderRadiusLG,
  colorBgContainer,
  colorBgSpotlight,
  colorBgElevated,
  borderRadiusSM,
  colorIconHover,
} = globalToken;
const {
  widthCloseButton,
  heightCloseButton,
  borderRadiusModal,
  paddingModal,
  paddingModalContent,
  colorModalBg,
  colorModalBorder,
  paddingModalHeader,
  colorModalHeaderBg,
} = globalToken.Modal;

export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  onClose,
  visible,
  style,
  closeOnBackdropClicked,
}) => {
  const { colorBgOverlay } = colorConfig;
  const { mode } = useThemeMode();
  return (
    <ModalImpl
      opened={visible}
      closeOnClickOutside={closeOnBackdropClicked}
      title={title}
      onClose={onClose ?? noop}
      centered
      styles={{
        overlay: {
          background: colorBgOverlay[mode],
        },
      }}
    >
      <div className={`${STYLE_PREFIX}-modal-content`} style={style}>
        {children}
      </div>
    </ModalImpl>
  );
};

export const ModalsProvider = ({
  children,
  ...props
}: ModalsProviderProps) => (
  <ModalsProviderImpl {...props}>
    <ModalStyle />
    <PopoverStyle />
    {children}
  </ModalsProviderImpl>
);

const ModalStyle = createGlobalStyle`
  .${STYLE_PREFIX}-modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: ${colorText};
    max-height: 80vh;
    overflow: auto;
  }

  .mantine-Paper-root {
    background-color: ${colorModalBg} !important;
    border-radius: ${borderRadiusModal}px;
    color: ${colorText} !important;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 11px 20px rgba(1, 2, 3, 0.42));
    border: 1px solid ${colorModalBorder};
    padding: ${
      isNumber(paddingModal) ? `${paddingModal}px` : paddingModal
    };
  }

  .mantine-Modal-close {
    border: none !important;
    &:hover {
      background-color: ${colorIconHover} !important;
    }
    &:focus {
      outline: none !important;
    }

    & svg {
      width: ${widthCloseButton}px;
      height: ${heightCloseButton}px;
    }
  }

  .mantine-Modal-header {
    color: ${colorText};
    font-size: 18px;
    padding: ${
      isNumber(paddingModalHeader)
        ? `${paddingModalHeader}px`
        : paddingModalHeader
    };
    background-color: ${colorModalHeaderBg};
    border-top-left-radius: ${borderRadiusLG}px;
    border-top-right-radius: ${borderRadiusLG}px;
    display: flex;
    flex: 1;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }

  .mantine-Modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${
      isNumber(paddingModalContent)
        ? `${paddingModalContent}px`
        : paddingModalContent
    };
    color: ${colorText};
    max-height: 80vh;
    overflow: auto;
  }

  .MuiList-padding {
    background-color: ${colorBgContainer} !important;
    padding: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiMenuItem-root {
    padding: 5px 12px !important;
    border-radius: ${borderRadiusSM}px !important;
    &:hover {
      background-color: ${colorBgSpotlight} !important;
    }
  }

  .MuiPopover-paper {
    background-color: ${colorBgContainer} !important;
    border: 1px solid ${colorBgElevated} !important;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
