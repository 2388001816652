import { colorConfig, useThemeMode } from '@robot/ui-theming';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const { colorInteractive } = colorConfig;
export const Spin: React.FC<{
  size: number;
  style?: React.CSSProperties;
}> = ({ size, style }) => {
  const { mode } = useThemeMode();
  return (
    <CircularProgress
      size={size}
      style={{
        color: colorInteractive[mode],
        ...style,
      }}
    />
  );
};
