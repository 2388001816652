// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  return {
    routes: {"components/MobileLanding":{"path":"components/MobileLanding","id":"components/MobileLanding","parentId":"@@/global-layout"},"components/common":{"path":"components/common","id":"components/common","parentId":"@@/global-layout"},"index":{"path":"/","id":"index","parentId":"@@/global-layout"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}},
    routeComponents: {
'components/MobileLanding': React.lazy(() => import(/* webpackChunkName: "src__pages__components__MobileLanding" */'../../../src/pages/components/MobileLanding.tsx')),
'components/common': React.lazy(() => import(/* webpackChunkName: "src__pages__components__common" */'../../../src/pages/components/common.tsx')),
'index': React.lazy(() => import(/* webpackChunkName: "src__pages__index" */'../../../src/pages/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/vercel/path0/packages/landing-web/src/layouts/index.tsx')),
},
  };
}
