import {
  BrandColorMapConfig,
  BrandColorMapToken,
  setTheme,
} from '@robot/ui-theming';

const colorPrimaryBg = {
  dark: 'rgba(179, 229, 248, 0.3);',
  light: 'linear-gradient(224.86deg, #147DFB 45.28%, #89E0FF 100%)',
};
const colorPrimaryBgDisabled = {
  dark: '#6BCBFD8C,#7FD2FE8C,#91D9FE8C,#98DBFE8C',
  light: '#6BCBFD8C,#7FD2FE8C,#91D9FE8C,#98DBFE8C',
};
const colorPrimaryBgActive = {
  dark: '#4ABDFC,#38B0F0,#219EE2,#0388CF',
  light: '#4ABDFC,#38B0F0,#219EE2,#0388CF',
};
const colorPrimaryBorder = {
  dark: '#B3E5F8',
  light: 'none',
};
const colorSuccessBg = {
  dark: '#66ffa4',
  light: 'rgba(27, 188, 34, 0.1)',
};
const colorSuccessBorder = {
  dark: 'rgba(153, 225, 119, 0.3)',
  light: 'none',
};
const colorWarningBg = {
  dark: '',
  light: '',
};
const colorWarningBorder = {
  dark: 'rgba(255, 157, 105, 1)',
  light: 'none',
};
const colorErrorBg = {
  dark: 'rgba(255, 138, 132, 0.2)',
  light: 'rgba(225, 97, 97, 0.1)',
};
const colorErrorBorder = {
  dark: '#FE746D',
  light: 'none',
};
const colorInfoBg = {
  dark: '#2B6DDD',
  light: 'linear-gradient(224.86deg, #147DFB 45.28%, #89E0FF 100%)',
};
const colorInfoBorder = {
  dark: '#FF8A84',
  light: 'none',
};

export const brandColorMapConfig: BrandColorMapConfig = {
  colorPrimaryBg,
  colorPrimaryBgDisabled,
  colorPrimaryBgActive,
  colorPrimaryBorder,
  colorSuccessBg,
  colorSuccessBorder,
  colorWarningBg,
  colorWarningBorder,
  colorErrorBg,
  colorErrorBorder,
  colorInfoBg,
  colorInfoBorder,
};

export const brandColorMapTokens: BrandColorMapToken = {
  colorPrimaryBg: setTheme(colorPrimaryBg),
  colorPrimaryBgActive: setTheme(colorPrimaryBgActive),
  colorPrimaryBgDisabled: setTheme(colorPrimaryBgDisabled),
  colorPrimaryBorder: setTheme(colorPrimaryBorder),
  colorSuccessBg: setTheme(colorSuccessBg),
  colorSuccessBorder: setTheme(colorSuccessBorder),
  colorWarningBg: setTheme(colorWarningBg),
  colorWarningBorder: setTheme(colorWarningBorder),
  colorErrorBg: setTheme(colorErrorBg),
  colorErrorBorder: setTheme(colorErrorBorder),
  colorInfoBg: setTheme(colorInfoBg),
  colorInfoBorder: setTheme(colorInfoBorder),
};
