/* eslint-disable func-names */
/* eslint-disable max-len */
import { colorConfig, useThemeMode } from '@robot/ui-theming';
import { FunctionComponent, ImgHTMLAttributes, SVGProps } from 'react';
import { createGlobalStyle } from 'styled-components';
import { STYLE_PREFIX } from '../common';
import { Txt, TxtProps } from '../txt';

const { colorBorderDisabled } = colorConfig;

const GlobalSVGCss = createGlobalStyle`
.${STYLE_PREFIX}-icon {
  display: inline-block;
  vertical-align: middle;

  svg path {
    fill: 'currentColor';
  }
}
`;

export const standardizeSvg = (
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  defaultProps?: SVGProps<SVGSVGElement>,
) =>
  function (overrideProps: SVGProps<SVGSVGElement>) {
    return (
      <>
        <GlobalSVGCss />
        <Icon
          className={`${STYLE_PREFIX}-icon`}
          height="1em"
          width="1em"
          preserveAspectRatio="xMidYMid"
          {...defaultProps}
          {...overrideProps}
        />
      </>
    );
  };

export const TxtIcon = (
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  defaultSvgProps?: SVGProps<SVGSVGElement>,
) =>
  function (txtProps: TxtProps) {
    const { style, ...restTxtProps } = txtProps;
    const SvgIcon = standardizeSvg(Icon);
    return (
      <Txt
        className={`${STYLE_PREFIX}-icon-wrapper`}
        style={{ lineHeight: 1, ...style }}
        {...restTxtProps}
      >
        <SvgIcon {...defaultSvgProps} />
      </Txt>
    );
  };

export const standardizeImg = (Icon: string) =>
  function (overrideProps: ImgHTMLAttributes<HTMLImageElement>) {
    const {
      style: overrideStyle,
      height,
      width,
      ...restOverrideProps
    } = overrideProps;
    const style = {
      height: height ?? '1em',
      width: width ?? '1em',
      ...overrideStyle,
    };
    return (
      <img src={Icon} alt="icon" style={style} {...restOverrideProps} />
    );
  };

/** SVG icon with circle background */
export const CircleIcon = (
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  backgroundColor?: string,
) =>
  function CircleIconComp(props: SVGProps<SVGSVGElement>) {
    const { mode } = useThemeMode();
    const { style, ...otherProps } = props;
    return (
      <Icon
        className={`${STYLE_PREFIX}-icon`}
        height="1em"
        width="1em"
        preserveAspectRatio="xMidYMid"
        style={{
          backgroundColor: backgroundColor ?? colorBorderDisabled[mode],
          borderRadius: '50%',
          ...style,
        }}
        {...otherProps}
      />
    );
  };
