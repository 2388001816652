import { colorConfig, useThemeMode } from '@robot/ui-theming';
import { Skeleton as SkeletonImpl, SkeletonProps } from '@mui/material';

export const Skeleton = ({ style, ...rest }: SkeletonProps) => {
  const { mode } = useThemeMode();

  return (
    <SkeletonImpl
      style={{
        transform: 'scale(1,1)',
        backgroundColor: colorConfig.colorBgTertiary[mode],
        ...style,
      }}
      {...rest}
    />
  );
};
