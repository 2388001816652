import { globalToken } from '@robot/ui-theming';
import cx from 'classnames';
import { isNumber, noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { IconCloseOutlined, Icons } from '../icon';
import { CenterRow, Col, View } from '../layout';
import { Txt } from '../txt';
import { StyledNotification } from './style';

const { marginXS, marginXXS } = globalToken;

export type NotificationType = 'info' | 'success' | 'warning' | 'error';

export type NotificationBaseProps = {
  closeable?: boolean;
  type: NotificationType;
  title?: React.ReactNode;
  content?: React.ReactNode;
  renderClose?: (options: { close: () => void }) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  onClose?: () => void;
  timestamp?: number | string;
  actions?: { label: string; onClick: () => void }[];
};

const getNotificationIcon = (type: NotificationType) => {
  switch (type) {
    case 'info':
      return Icons.Info;
    case 'success':
      return Icons.Success;
    case 'error':
      return Icons.Error;
    case 'warning':
      return Icons.Warning;
    default:
      return () => null;
  }
};

export const NotificationBase: React.FC<NotificationBaseProps> = ({
  content,
  type,
  title,
  closeable,
  renderClose,
  onClose,
  style,
  bodyStyle,
  className,
  timestamp,
  actions,
}) => {
  const [notifyProgressPct, setProgressPct] = useState(0);
  const closeIcon = renderClose ? (
    renderClose({ close: onClose ?? noop })
  ) : (
    <IconCloseOutlined
      className="icon notification-close"
      onClick={() => {
        onClose?.();
      }}
    />
  );
  const HeadingIcon = useMemo(() => getNotificationIcon(type), [type]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressPct(pct => (pct + 10 >= 93 ? 93 : pct + 10));
    }, 400);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setProgressPct(0);
  }, [type]);
  return (
    <StyledNotification notifyProgressPct={notifyProgressPct}>
      <Txt
        block
        c1
        className={cx(
          'aries-notification',
          `aries-notification-${type} `,
          className,
        )}
        style={style}
      >
        <CenterRow style={{ flex: 1 }}>
          <HeadingIcon
            style={{ fontSize: 28 }}
            mr={marginXS}
            className="heading-icon"
          />
          <Col className="notification-body" style={bodyStyle}>
            {title && (
              <CenterRow className="title-container">
                <Txt flex={1} className="title">
                  {title}
                </Txt>
                {closeable && closeIcon}
              </CenterRow>
            )}

            {content && (
              <Txt c2 h2 mt={marginXXS} className="content">
                {content}
              </Txt>
            )}
            {actions?.map(a => (
              <div className="manage" key={a.label} onClick={a.onClick}>
                {a.label}
              </div>
            ))}
            {timestamp && (
              <Txt block h1 className="time">
                {isNumber(timestamp)
                  ? new Date(timestamp).toLocaleDateString()
                  : timestamp}
              </Txt>
            )}
          </Col>
          <View className="progress-bar" />
        </CenterRow>
      </Txt>
    </StyledNotification>
  );
};
