import { colorConfig, useThemeMode } from '@robot/ui-theming';
import { Timeline as TimelineImpl } from 'antd';
import { isString } from 'lodash';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { CenterRow } from '../layout';
import { Txt } from '../txt';

const { Item } = TimelineImpl;

type Milestone = {
  position?: string;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  date?: Date;
};

export const Timeline = ({
  milestones,
  currentIndex = 0,
  style,
}: {
  milestones: Milestone[];
  currentIndex?: number;
  style?: CSSProperties;
}) => {
  const { mode } = useThemeMode();
  return (
    <InnerTimeline style={style}>
      {milestones.map(({ icon, title, position, style, date }, index) => (
        <Item
          dot={
            <CenterRow
              style={{
                width: 32,
                height: 32,
                justifyContent: 'center',
                borderRadius: '50%',
                background:
                  index === currentIndex
                    ? colorConfig.colorPrimary[mode]
                    : colorConfig.colorBgQuaternary[mode],
              }}
            >
              {icon}
            </CenterRow>
          }
          position={position}
          style={{
            opacity: index > currentIndex ? '.7' : '1',
            height: 70,
            ...style,
          }}
          key={index}
          color={
            index !== currentIndex ? colorConfig.colorText[mode] : '#fff'
          }
        >
          <CenterRow gap={12} fullWidth>
            {isString(title) ? (
              <Txt h3 bolder color="colorText" flex={2}>
                {title}
              </Txt>
            ) : (
              title
            )}
            {date && (
              <Txt c3 h1 bolder flex={1}>
                {dayjs(date).format('MMM DD h:mm a')}
              </Txt>
            )}
          </CenterRow>
        </Item>
      ))}
    </InnerTimeline>
  );
};

const InnerTimeline = styled(TimelineImpl)`
  margin-left: 16px;
  margin-top: 16px;

  & .ant-timeline-item {
    &-head {
      background-color: transparent;
    }

    &-tail {
      height: calc(100% - 44px);
      inset-block-start: 27px;
    }

    &-content {
      margin-inline-start: 36px;
      inset-block-start: -11px;
      display: flex;
      align-items: center;
      min-height: auto !important;
      height: 32px;
    }
  }
`;
