import { NotificationsProvider } from '@mantine/notifications';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ModalsProvider } from './modal';
import { NotificationProvider } from './notification/provider';

// Integrate some UI logic into app
export const UIProvider: React.FC<{ children: React.ReactChild }> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={{ mode: 'dark' }}>
      <NotificationsProvider>
        <ModalsProvider>
          <NotificationProvider>{children}</NotificationProvider>
        </ModalsProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
};
