import { globalToken } from '@robot/ui-theming';
import SwitchUnstyled, {
  switchUnstyledClasses,
} from '@mui/base/SwitchUnstyled';
import * as React from 'react';
import styled from 'styled-components';

const { transition, colorText, colorTextSecondary, colorTextTertiary } =
  globalToken;

const SwitchStyle = globalToken.Switch;
const {
  borderRadiusSwitchTrack,
  borderRadiusSwitchThumb,
  colorSwitchBg,
  colorSwitchBorder,
  colorSwitchBgChecked,
  colorSwitchBgCheckedDisabled,
} = SwitchStyle;

const Root = styled.span`
  font-size: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 24px;

  &.${switchUnstyledClasses.disabled} {
    cursor: not-allowed;
    .${switchUnstyledClasses.track} {
      border: 1px solid ${colorSwitchBorder};
      background: ${colorSwitchBg};
    }
    .${switchUnstyledClasses.thumb} {
      background: ${colorTextTertiary};
    }
  }

  & .${switchUnstyledClasses.track} {
    border: 1px solid ${colorSwitchBorder};
    background: ${colorSwitchBg};
    border-radius: ${borderRadiusSwitchTrack}px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 18px;
    height: 18px;
    top: 2px;
    left: 2px;
    border-radius: ${borderRadiusSwitchThumb}px;
    background-color: ${colorTextSecondary};
    box-shadow: 0px 11px 20px rgba(1, 2, 3, 0.42);
    position: relative;
    transition: all ${transition};
  }

  &
    .${switchUnstyledClasses.focusVisible}
    .${switchUnstyledClasses.thumb} {
    background-color: ${colorText};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 19px;
      top: 3px;
      background-color: ${colorText};
    }

    .${switchUnstyledClasses.track} {
      border: none;
      background: ${colorSwitchBgChecked};
    }

    &.${switchUnstyledClasses.disabled} .${switchUnstyledClasses.track} {
      background: ${colorSwitchBgCheckedDisabled};
    }

    &.${switchUnstyledClasses.disabled} .${switchUnstyledClasses.thumb} {
      background: ${colorTextSecondary};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }

  &.sm {
    width: 34px;
    height: 20px;

    & > .${switchUnstyledClasses.thumb} {
      width: 16px;
      height: 16px;
    }

    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 16px;
        top: 2px;
      }
    }
  }
`;

export type SwitchProps = {
  checked: boolean;
  onChange: (v: boolean) => void;
  disabled?: boolean;
  size?: 'lg' | 'sm';
};

export const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  disabled,
  size = 'lg',
}) => (
  <SwitchUnstyled
    className={size}
    component={Root as any}
    checked={checked}
    disabled={disabled}
    componentsProps={{
      root: {
        onClick: (e: { stopPropagation: () => void }) => {
          e.stopPropagation();
        },
      },
    }}
    onChange={() => {
      // @ts-ignore
      onChange(!checked);
    }}
  />
);
