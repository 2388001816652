import { css } from 'styled-components';

import { useWindowWidth } from '@robot/shared/utils';

export type Breakpoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type BreakpointMap = Record<Breakpoint, string>;
export type ScreenMap = Partial<Record<Breakpoint, boolean>>;
export type ScreenSizeMap = Partial<Record<Breakpoint, number>>;

export const screenArray: Breakpoint[] = [
  'xxl',
  'xl',
  'lg',
  'md',
  'sm',
  'xs',
];

export const responsiveMap: BreakpointMap = {
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1600px)',
};

type CssParamsType = Parameters<typeof css>;
// Iterate through the breakpoints and create a media template
export const media = screenArray.reduce(
  (
    acc: Record<Breakpoint, (...params: CssParamsType) => any>,
    breakpoint,
  ) => {
    acc[breakpoint] = (...args: CssParamsType) => css`
      @media ${responsiveMap[breakpoint]} {
        ${css(...args)}
      }
    `;

    return acc;
  },
  {} as any,
);

const showScreenCondition: Record<Breakpoint, (width: number) => boolean> =
  {
    xs: width => width < 575,
    sm: width => width > 576,
    md: width => width > 768,
    lg: width => width > 992,
    xl: width => width > 1200,
    xxl: width => width > 1600,
  };
export const useShowScreens = (): Breakpoint[] => {
  const width = useWindowWidth();
  return screenArray.filter(screen => showScreenCondition[screen](width));
};

export const useIsMobile = () => useShowScreens().includes('xs');

export const useIsLgScreen = () => useShowScreens().includes('lg');

export const useIsMdScreen = () => useShowScreens().includes('md');
